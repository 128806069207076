import { ActivityType, type UserGroup, type UserRole } from '~/models'

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiY2FtZXJvbi1yZXNpbXBsaWZpIiwiYSI6ImNremc0cHI5aTAyNHgybnBocWV4NHNoY2MifQ.JAWWThmFY0TwUARWnv1ctA'

export const SERVE_ACCESS_TOKEN =
  'pk.eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQ0FSTk0ifQ.KXEJ0TDslrQgb8sQjykUeB955-oVWouwjnK1CkuSxC4'

export const CLUSTER_ZOOM_LEVEL = 10

export const TYPES: { [key: string]: string } = {
  sale: 'Sale',
  lease: 'Lease',
  saleLease: 'Sale/Lease',
}

export const STATUSES: { [key: string]: string } = {
  deleted: 'Deleted',
  draft: 'Draft',
  active: 'Active',
  pending: 'Pending',
  sold: 'Sold',
  leased: 'Fully Leased',
  withdrawn: 'Withdrawn',
  expired: 'Expired',
}

export const ACTIVITY_LABELS: { [key: string]: string } = {
  [ActivityType.view]: 'View',
  [ActivityType.preview]: 'Preview',
  [ActivityType.download]: 'Download',
  [ActivityType.viewLink]: 'Opened Link',
  [ActivityType.update]: 'Updated',
  [ActivityType.create]: 'Created',
  [ActivityType.delete]: 'Deleted',
}

export const USER_GROUPS: { [key in keyof typeof UserGroup]: string } = {
  superAdmin: 'Super Admin',
  organizationAdmin: 'Organization Admin',
  companyAdmin: 'Company Admin',
  appraiser: 'Appraiser',
  member: 'Member',
  // viewer: 'Viewer',
}

export const USER_ROLES: { [key in keyof typeof UserRole]: string } = {
  managingBroker: 'Managing Broker',
  coordinator: 'Transaction Coordinator',
  broker: 'Broker',
}

export const PROPERTY_TYPES_COLORS = [
  {
    value: 'orange',
    propertyType: 'Healthcare',
    className: 'bg-[#fb923c] hover:bg-[#fb923c]',
  },
  {
    value: 'pink',
    propertyType: 'Hospitality',
    className: 'bg-[#fb7185] hover:bg-[#fb7185]',
  },
  {
    value: 'slate',
    propertyType: 'Industrial',
    className: 'bg-[#475569] hover:bg-[#475569]',
  },
  {
    value: 'emerald',
    propertyType: 'Investment',
    className: 'bg-[#34d399] hover:bg-[#34d399]',
  },
  {
    value: 'indigo',
    propertyType: 'Land',
    className: 'bg-[#818cf8] hover:bg-[#818cf8]',
  },
  {
    value: 'fuchsia',
    propertyType: 'Multifamily',
    className: 'bg-[#e879f9] hover:bg-[#e879f9]',
  },
  {
    value: 'yellow',
    propertyType: 'Office',
    className: 'bg-[#fde047] hover:bg-[#fde047] text-[#030712]',
  },
  {
    value: 'cyan',
    propertyType: 'Retail',
    className: 'bg-[#22d3ee] hover:bg-[#22d3ee]',
  },
  {
    value: 'stone',
    propertyType: 'Other',
    className: 'bg-[#a8a29e] hover:bg-[#a8a29e]',
  },
]

export const PROPERTY_TYPES: string[] = [
  'Healthcare',
  'Hospitality',
  'Industrial',
  'Investment',
  'Land',
  'Multifamily',
  'Office',
  'Retail',
  'Other',
]

export const HOSPITALITY_SUBTYPES: string[] = [
  'Bed & Breakfast',
  'Casino',
  'Chalet',
  'Convention Center',
  'Extended Stay',
  'Golf Course',
  'Hostel',
  'Hotel',
  'Inn',
  'Motel',
  'Recreation Cabins',
  'Resort',
  'Ski & Sun',
  'Spa',
  'Vacation Rental(s)',
]

export const INDUSTRIAL_SUBTYPES: string[] = [
  'Cannabis',
  'Cold Storage',
  'Flex Space',
  'Food Processing',
  'Free Standing',
  'Industrial-Business Park',
  'Light Industrial',
  'Manufacturing',
  'Mixed Use',
  'Net Leased',
  'Office Showroom',
  'Research & Development',
  'Self Storage',
  'Truck Terminal',
  'Warehouse/Distribution',
  'Warehouse/Flex',
  'Warehouse/Office',
]

export const LAND_SUBTYPES: string[] = [
  'Agriculture',
  'Hospitality',
  'Industrial',
  'Mobile Home Park',
  'Multifamily',
  'Office',
  'Residential Development',
  'Retail',
  'Retail Pad',
  'Self Storage',
  'Vacation/Resort',
  'EV Charging Station',
]

export const MULTIFAMILY_SUBTYPES: string[] = [
  'Duplex/Quadplex',
  'Government Subsidized',
  'High Rise',
  'Low Rise',
  'Mid Rise',
  'Mixed Use',
  'Mobile Home Park',
  'Senior Living',
  'Student Housing',
  'Townhouse',
]

export const OFFICE_SUBTYPES: string[] = [
  'Business Park',
  'Executive Suites',
  'Coworking',
  'Data Center/Call Center',
  'Financial',
  'Governmental',
  'High-Tech',
  'Industrial',
  'Institutional',
  'Medical',
  'Mixed Use',
  'Flex Space',
  'Net Leased',
  'Office Building',
  'Research & Development',
]

export const RETAIL_SUBTYPES: string[] = [
  'Bank',
  'Big Box',
  'Cannabis Dispensary',
  'Car Wash',
  'Convenience Store',
  'Day Care Facility',
  'Free Standing',
  'Garden Center',
  'Mixed Use',
  'Movie Theater',
  'Post Office',
  'Restaurant',
  'Retail-Pad',
  'Service Station/Gas Station',
  'Showroom',
  'Street Retail',
  'Strip Center',
  'Tavern/Bar/Nightclub',
  'Vehicle Related',
]

export const OTHER_SUBTYPES: string[] = [
  'Airport',
  'Church',
  'Funeral Home',
  'Hospital',
  'Parking Facility',
  'School',
  'Sports Center',
]

export const PROPERTY_SUBTYPE_MAP: { [key: string]: string[] } = {
  Hospitality: HOSPITALITY_SUBTYPES,
  Industrial: INDUSTRIAL_SUBTYPES,
  Land: LAND_SUBTYPES,
  Multifamily: MULTIFAMILY_SUBTYPES,
  Office: OFFICE_SUBTYPES,
  Retail: RETAIL_SUBTYPES,
  Other: OTHER_SUBTYPES,
}

export const RATE_TYPES: { [key: string]: string } = {
  sfYear: 'SF/Year',
  month: 'Month',
  year: 'Year',
  sfMonth: 'SF/Month',
  acMonth: 'Acre/Month',
  acYear: 'Acre/Year',
}

export const EMAIL_LISTING_PROJECT = {
  created: 1,
  modified: 1,
  name: 1,
  slug: 1,
  property: 1,
  type: 1,
  status: 1,
  propertyTypes: 1,
  images: { $slice: 1 },
}

// prettier-ignore
export const STATE_ABBRS = ['AL','AK','AZ','AR','CA','CO','CT','DE','DC','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VI','VA','WA','WV','WI','WY',]

export const ZONES: string[] = [
  'R-A',
  'R-1A',
  'R-1B',
  'R-1C',
  'R-1D',
  'R-T',
  'R-MC',
  'R-ML',
  'R-MH',
  'MX-T',
  'MX-L',
  'MX-M',
  'MX-H',
  'MX-FB-ID',
  'MX-FB-FX',
  'MX-FB-UD',
  'NR-C',
  'NR-BP',
  'NR-LM',
  'NR-GM',
  'NR-SU',
  'NR-PO-A',
  'NR-PO-B',
  'NR-PO-C',
  'NR-PO-D',
  'PD',
  'PC',
  'UNCL',
  'A-1 Rural Agricultural Zone',
  'A-2 Rural Agricultural Zone',
  'R-1 Single-Family Residential Zone',
  'R-2 Apartment Zone',
  'M-H Mobile Home and Single-Family Residential Zone',
  'O-1 Office and Institutional Zone',
  'C-N Neighborhood Commercial Zone',
  'C-1 Neighborhood Commercial Zone',
  'C-2 Community Commercial Zone',
  'C-LI Commercial/Light Industrial Zone',
  'M-1 Light Industrial Zone',
  'M-2 Heavy Manufacturing Zone',
  'OZ Overlay Zone',
  'SD Sector Development Zone',
]

export const ZIP_CODES = [
  '87001',
  '87002',
  '87004',
  '87005',
  '87006',
  '87007',
  '87008',
  '87009',
  '87010',
  '87011',
  '87012',
  '87013',
  '87014',
  '87015',
  '87016',
  '87017',
  '87018',
  '87020',
  '87021',
  '87022',
  '87023',
  '87024',
  '87025',
  '87026',
  '87027',
  '87028',
  '87029',
  '87031',
  '87032',
  '87034',
  '87035',
  '87036',
  '87037',
  '87038',
  '87040',
  '87041',
  '87042',
  '87043',
  '87044',
  '87045',
  '87046',
  '87047',
  '87048',
  '87049',
  '87051',
  '87052',
  '87053',
  '87056',
  '87059',
  '87060',
  '87061',
  '87062',
  '87063',
  '87064',
  '87068',
  '87070',
  '87072',
  '87083',
  '87101',
  '87102',
  '87103',
  '87104',
  '87105',
  '87106',
  '87107',
  '87108',
  '87109',
  '87110',
  '87111',
  '87112',
  '87113',
  '87114',
  '87115',
  '87116',
  '87117',
  '87119',
  '87120',
  '87121',
  '87122',
  '87123',
  '87124',
  '87125',
  '87131',
  '87144',
  '87151',
  '87153',
  '87154',
  '87158',
  '87165',
  '87174',
  '87176',
  '87181',
  '87184',
  '87185',
  '87187',
  '87190',
  '87191',
  '87192',
  '87193',
  '87194',
  '87195',
  '87196',
  '87197',
  '87198',
  '87199',
  '87301',
  '87302',
  '87305',
  '87310',
  '87311',
  '87312',
  '87313',
  '87315',
  '87316',
  '87317',
  '87319',
  '87320',
  '87321',
  '87322',
  '87323',
  '87325',
  '87326',
  '87327',
  '87328',
  '87347',
  '87357',
  '87364',
  '87365',
  '87375',
  '87401',
  '87402',
  '87410',
  '87412',
  '87413',
  '87415',
  '87416',
  '87417',
  '87418',
  '87419',
  '87420',
  '87421',
  '87455',
  '87461',
  '87499',
  '87501',
  '87502',
  '87503',
  '87504',
  '87505',
  '87506',
  '87507',
  '87508',
  '87509',
  '87510',
  '87511',
  '87512',
  '87513',
  '87514',
  '87515',
  '87516',
  '87517',
  '87518',
  '87519',
  '87520',
  '87521',
  '87522',
  '87523',
  '87524',
  '87525',
  '87527',
  '87528',
  '87529',
  '87530',
  '87531',
  '87532',
  '87533',
  '87535',
  '87537',
  '87538',
  '87539',
  '87540',
  '87543',
  '87544',
  '87545',
  '87547',
  '87548',
  '87551',
  '87552',
  '87553',
  '87554',
  '87556',
  '87557',
  '87558',
  '87560',
  '87562',
  '87564',
  '87565',
  '87566',
  '87567',
  '87569',
  '87571',
  '87573',
  '87574',
  '87575',
  '87576',
  '87577',
  '87578',
  '87579',
  '87580',
  '87581',
  '87582',
  '87583',
  '87592',
  '87594',
  '87654',
  '87701',
  '87710',
  '87711',
  '87712',
  '87713',
  '87714',
  '87715',
  '87718',
  '87722',
  '87723',
  '87724',
  '87728',
  '87729',
  '87730',
  '87731',
  '87732',
  '87733',
  '87734',
  '87735',
  '87736',
  '87740',
  '87742',
  '87743',
  '87745',
  '87746',
  '87747',
  '87749',
  '87750',
  '87752',
  '87753',
  '87801',
  '87820',
  '87821',
  '87823',
  '87824',
  '87825',
  '87827',
  '87828',
  '87829',
  '87830',
  '87831',
  '87832',
  '87831',
  '87832',
  '87901',
  '87930',
  '87931',
  '87933',
  '87935',
  '87936',
  '87937',
  '87939',
  '87940',
  '87941',
  '87942',
  '87943',
  '88001',
  '88002',
  '88003',
  '88004',
  '88005',
  '88006',
  '88007',
  '88008',
  '88009',
  '88011',
  '88012',
  '88013',
  '88020',
  '88021',
  '88022',
  '88023',
  '88024',
  '88025',
  '88026',
  '88027',
  '88028',
  '88029',
  '88030',
  '88031',
  '88032',
  '88033',
  '88034',
  '88036',
  '88038',
  '88039',
  '88040',
  '88041',
  '88042',
  '88043',
  '88044',
  '88045',
  '88046',
  '88047',
  '88048',
  '88049',
  '88051',
  '88052',
  '88053',
  '88054',
  '88055',
  '88056',
  '88058',
  '88061',
  '88062',
  '88063',
  '88065',
  '88072',
  '88081',
  '88101',
  '88102',
  '88103',
  '88112',
  '88113',
  '88114',
  '88115',
  '88116',
  '88118',
  '88119',
  '88120',
  '88121',
  '88122',
  '88123',
  '88124',
  '88125',
  '88126',
  '88130',
  '88132',
  '88133',
  '88134',
  '88135',
  '88136',
  '88201',
  '88202',
  '88203',
  '88210',
  '88211',
  '88213',
  '88220',
  '88221',
  '88230',
  '88231',
  '88232',
  '88240',
  '88241',
  '88242',
  '88244',
  '88250',
  '88252',
  '88253',
  '88254',
  '88255',
  '88256',
  '88260',
  '88262',
  '88263',
  '88264',
  '88265',
  '88267',
  '88268',
  '88272',
  '88273',
  '88301',
  '88310',
  '88311',
  '88312',
  '88314',
  '88316',
  '88317',
  '88318',
  '88321',
  '88323',
  '88324',
  '88325',
  '88330',
  '88336',
  '88337',
  '88338',
  '88339',
  '88340',
  '88341',
  '88342',
  '88343',
  '88344',
  '88345',
  '88346',
  '88347',
  '88348',
  '88349',
  '88350',
  '88351',
  '88352',
  '88353',
  '88354',
  '88355',
  '88356',
  '88401',
  '88410',
  '88411',
  '88414',
  '88415',
  '88416',
  '88417',
  '88418',
  '88419',
  '88421',
  '88422',
  '88424',
  '88426',
  '88427',
  '88430',
  '88431',
  '88433',
  '88434',
  '88435',
  '88436',
  '88439',
]

export const NM_CITIES = [
  'Alamogordo',
  'Albuquerque',
  'Alcalde ',
  'Algodones',
  'Angel Fire',
  'Anthony ',
  'Artesia ',
  'Aztec ',
  'Bayard ',
  'Belen ',
  'Bernalillo',
  'Bloomfield ',
  'Bosque Farms',
  'Brimhall ',
  'Cannon Afb',
  'Capitan ',
  'Carlsbad ',
  'Carrizozo ',
  'Cedar Crest',
  'Chama ',
  'Chamisal',
  'Chaparral',
  'Chimayo ',
  'Church Rock',
  'Cimarron ',
  'Clayton',
  'Cloudcroft',
  'Clovis',
  'Cochiti Lake',
  'Cochiti Pueblo',
  'Columbus',
  'Corrales',
  'Crownpoint',
  'Cuba',
  'Deming',
  'Dexter',
  'Dona Ana',
  'Dulce',
  'Eagle Nest',
  'Edgewood',
  'Elephant Butte',
  'Espanola',
  'Estancia',
  'Eunice',
  'Farmington',
  'Flora Vista',
  'Fort Sumner',
  'Gallup',
  'Glorieta',
  'Grants',
  'Hagerman',
  'Hatch',
  'Hobbs',
  'Holloman Air Force Base',
  'Hurley',
  'Isleta',
  'Jal',
  'Jarales',
  'Jemez Pueblo',
  'Jemez Springs',
  'Kirtland',
  'La Jara',
  'La Luz',
  'Laguna',
  'Lake Arthur',
  'Las Cruces',
  'Las Vegas',
  'Logan',
  'Lordsburg',
  'Los Alamos',
  'Los Lunas',
  'Loving',
  'Lovington',
  'Magdalena',
  'Maxwell',
  'Melrose',
  'Mescalero',
  'Mesilla',
  'Mesquite',
  'Milan',
  'Moriarty',
  'Mountainair',
  'Nageezi',
  'Navajo',
  'Newcomb',
  'Paguate',
  'Pecos',
  'Pena Blanca',
  'Penasco',
  'Peralta',
  'Placitas',
  'Ponderosa',
  'Portales',
  'Questa',
  'Radium Springs',
  'Ramah',
  'Ranchos De Taos',
  'Raton',
  'Red River',
  'Reserve',
  'Rincon',
  'Rio Rancho',
  'Roswell',
  'Roy',
  'Ruidoso Downs',
  'Ruidoso',
  'Salem',
  'San Jon',
  'San Juan Pueblo',
  'San Ysidro',
  'Sanostee',
  'Santa Clara',
  'Santa Cruz',
  'Santa Fe',
  'Santa Rosa',
  'Santa Teresa',
  'Santo Domingo Pueblo',
  'Sheep Springs',
  'Shiprock',
  'Silver City',
  'Socorro',
  'Springer',
  'Sunland Park',
  'Taos',
  'Tatum',
  'Tesuque',
  'Texico',
  'Thoreau',
  'Tijeras',
  'Timberon',
  'Tohatchi',
  'Tome',
  'Torreon',
  'Truth Or Consequences',
  'Tucumcari',
  'Tularosa',
  'Vadito',
  'Vado',
  'Vaughn',
  'Wagon Mound',
  'White Sands Missile Range',
  'Willard',
  'Williamsburg',
  'Yatahey',
  'Zuni',
]

export const NM_COUNTIES = [
  'Bernalillo',
  'Catron',
  'Chaves',
  'Cibola',
  'Colfax',
  'Curry',
  'De Baca',
  'Doña Ana',
  'Eddy',
  'Grant',
  'Guadalupe',
  'Harding',
  'Hidalgo',
  'Lea',
  'Lincoln',
  'Los Alamos',
  'Luna',
  'McKinley',
  'Mora',
  'Otero',
  'Quay',
  'Rio Arriba',
  'Roosevelt',
  'San Juan',
  'San Miguel',
  'Sandoval',
  'Santa Fe',
  'Sierra',
  'Socorro',
  'Taos',
  'Torrance',
  'Union',
  'Valencia',
]
